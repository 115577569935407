<template>
  <div class="h-full">
    <div class="bottom_space rounded py-2 h-full bg-white">
      <div v-if="!isLoading">
        <div style="overflow-y: autos; white-space: nowrap;" class="p-3 lg:grid grid-cols-4 gap-4">
          <div class="col-span-2 ">
            <div class="card rounded-md p-2 mb-4 flex items-center ">
              <!-- <span @click="$router.push({name: 'StaffListing'})"><i class="fas fa-chevron-left text-text2" ></i></span>
              <div class="heading-4 text-text2 pl-4 font-semibold">Staff Detail</div> -->
              <div class="text-primary cursor-pointer" @click="$router.push({name: 'StaffListing'})">Staff List</div>
              <div class="px-3"><span><i class="fas fa-chevron-right text-gray2" ></i></span></div>
              <div class="text-text2">Staff Detail</div>
            </div>
            <div class="card rounded-md p-2 mb-4">
              <div class="flex items-center justify-between">
                <div class="text-text1" :class="mobileView ? 'pb-2 heading-5' : 'heading-4'">● User Info.:</div>
                <div v-if="!openUserIsEdit" class=" text-primary heading-3 cursor-pointer rounded-xl" @click.stop="userEdit()">
                  <i class="fas fa-pen-to-square"></i>
                </div>
                <span v-if="openUserIsEdit" class="flex items-center">
                  <div class=" text-white heading-5 cursor-pointer bg-error px-2.5 py-1 rounded-full" @click.stop="closeUserInfoEdit(false)">
                    <i class="fas fa-times"></i>
                  </div>
                  <div class=" text-white heading-5 cursor-pointer bg-secondary px-2.5 py-1 rounded-full ml-2" @click.stop="closeUserInfoEdit(true)">
                    <i class="fas fa-check"></i>
                  </div>
                </span>
              </div>
              <div class="flex pl-2">
                <div class="w-full" v-if="!openUserIsEdit">
                    <div class="">
                      <div class="gap-4 lg:grid grid-cols-2">
                        <p class="mb-0 pt-2">
                          <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">First Name:</p></span>
                          <span class="text-text2 heading-5 font-semibold" >{{staffData.firstName}}</span>
                        </p>
                        <p class="mb-0 pt-2">
                          <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Last Name:</p></span>
                          <span class="text-text2 heading-5 font-semibold" >{{staffData.lastName}}</span>
                        </p>
                      </div>
                      <p class="mb-0 pt-2">
                        <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Cell Number:</p></span>
                        <span class="text-text2 heading-5 font-semibold" >{{staffData.phoneNo | phone}}</span>
                      </p>
                    </div>
                </div>
                <div class="w-full" v-if="openUserIsEdit">
                  <div class="my-2">
                    <div class="gap-4 lg:grid grid-cols-2">
                      <div class="mb-4 sm:mb-0">
                        <TextField
                          :inputId="'contactnameinput1'"
                          :inputext="staffInfoObj.firstName"
                          :placholderText="`First Name`"
                          :lableText="'First Name'"
                          :autoFocus="false"
                          :fieldError="fNameErr !== ''"
                          @keyPressAction="fNameErr = ''"
                          @inputChangeAction="(data) => staffInfoObj.firstName = data"  />
                          <p class="text-error heading-6">{{fNameErr}}</p>
                        </div>
                      <div class="mb-4 sm:mb-0">
                        <TextField
                          :inputId="'contactnameinput2'"
                          :inputext="staffInfoObj.lastName"
                          :placholderText="`Last Name`"
                          :lableText="'Last Name'"
                          :autoFocus="false"
                          :fieldError="lNameErr !== ''"
                          @keyPressAction="lNameErr = ''"
                          @inputChangeAction="(data) => staffInfoObj.lastName = data"  />
                          <p class="text-error heading-6">{{lNameErr}}</p>
                      </div>
                      <div class="mb-4 sm:mb-0">
                        <TextField
                          :textMaxlength="12"
                          :inputId="'contactnameinput3'"
                          :inputext="staffInfoObj.phoneNo"
                          :placholderText="`Cell Number`"
                          :lableText="'Cell Number'"
                          :autoFocus="false"
                          :fieldError="cellErr !== ''"
                          @blurAction="checkContactNumber()"
                          @keyPressAction="cellErr = ''"
                          @inputChangeAction="(data) => staffInfoObj.phoneNo = data"  />
                          <p class="text-error heading-6">{{cellErr}}</p>
                      </div>
                    </div>
                    <div class="flex items-center pt-4" v-if="!staffData.isS">
                      <label class="cu_switch cursor-pointer mr-3" :class="!staffInfoObj.isActive ? 'ml-2' : ''">
                        <input type="checkbox" v-model="staffInfoObj.isActive">
                        <span class="cu_slider cu_round"></span>
                      </label>
                      <p class="text-gray3 pl-1">{{staffInfoObj.isActive ? 'is Active' : 'is Inactive'}}</p>
                    </div>
                  </div>
                  <p v-if="staffData.isS" class=" heading-5 font-semibold pl-1 text-text2">This staff is Super Admin</p>
              </div>
              </div>
            </div>
            <div class="card rounded-md p-2 mb-4">
              <div class="flex items-center justify-between">
                <div class="text-text1" :class="mobileView ? 'pb-2 heading-5' : 'heading-4'">● Login Details for User:</div>
                <div v-if="!openLoginEdit" class=" text-primary heading-3 cursor-pointer rounded-xl" @click.stop="logInDataEdit()">
                  <i class="fas fa-pen-to-square"></i>
                </div>
                <span v-if="openLoginEdit" class="flex items-center">
                  <div class=" text-white heading-5 cursor-pointer bg-error px-2.5 py-1 rounded-full" @click.stop="updateUserLoginDetail(false)">
                    <i class="fas fa-times"></i>
                  </div>
                  <div class=" text-white heading-5 cursor-pointer bg-secondary px-2.5 py-1 rounded-full ml-2" @click.stop="updateUserLoginDetail(true)">
                    <i class="fas fa-check"></i>
                  </div>
                </span>
              </div>
              <div class="pl-2" v-if="!openLoginEdit">
                <div class="sm:grid grid-cols-2 gap-4 mb-4">
                  <div class="mb-4 sm:mb-0">
                    <p class="mb-0 pt-2">
                      <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Email Address:</p></span>
                      <span class="text-text2 heading-5 font-semibold" >{{staffLoginData.emailAddress}}</span>
                    </p>
                  </div>
                  <div class="mb-4 sm:mb-0">
                    <p class="mb-0 pt-2">
                      <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Password:</p></span>
                      <span class="text-text2 heading-5 font-semibold" >*********</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="pl-2 py-2" v-if="openLoginEdit">
                <div class="sm:grid grid-cols-2 gap-4 mb-4">
                  <div class="mb-4 sm:mb-0">
                    <TextField
                      :inputId="`useremailid`"
                      :inputext="loginObj.emailAddress"
                      :placholderText="`Email Address`"
                      :lableText="'Email Address'"
                      :fieldError="emailErr !== ''"
                      :autoFocus="true"
                      :inputType="'text'"
                      @keyPressAction="emailErr = ''"
                      @blurAction="emailHandler(loginObj.emailAddress)"
                      @inputChangeAction="(data) => loginObj.emailAddress = data"  />
                      <p class="text-error"> {{ emailErr }}</p>
                  </div>
                  <div class="mb-4 sm:mb-0">
                    <TextField
                      :inputId="`userpassid`"
                      :inputext="loginObj.password"
                      :placholderText="`Password`"
                      :lableText="'Password'"
                      :fieldError="passErr !== ''"
                      :autoFocus="false"
                      :inputType="'password'"
                      @keyPressAction="passErr = ''"
                      @inputChangeAction="(data) => loginObj.password = data"  />
                      <p class="text-error"> {{ passErr }}</p>
                    <!-- <p class="mb-0 pt-2">
                      <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Password:</p></span>
                      <span class="text-text2 heading-5 font-semibold" >*********</span>
                    </p> -->
                  </div>
                </div>
              </div>
            </div>
            <div  v-if="!openUserIsEdit" class="card rounded-md p-2">
              <p v-if="!staffData.isS" class=" heading-5 font-semibold pl-1" :class="!staffInfoObj.isActive ? 'text-error' : 'text-text2'">{{staffInfoObj.isActive ? 'This staff is Active' : 'This staff is Inactive'}}</p>
              <p v-if="staffData.isS" class=" heading-5 font-semibold pl-1 text-text2">This staff is Super Admin</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button v-show="scY > 200" @click="toTop" class="bg-primary rounded-full h-10 w-10 fixed bottom-3 right-2"><i class="fas fa-caret-up text-white"></i></button>
  </div>
</template>
<script>
import {getEncryptedPassword} from '@/utils/encrytPassword'
import { validateEmail, checkSpeialChar } from "@/utils/validation.js";
import TextField from '@/View/components/textfield.vue'
// import TextAreaInput from '@/View/components/textAreaInput.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
// import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    TextField,
    // TextAreaInput
  },
  mixins: [deboucneMixin],
  data () {
    return {
      staffData: {},
      staffLoginData: {},
      staffInfoObj: {
        userDetailId: 0,
        firstName: '',
        lastName: '',
        phoneNo: '',
        isActive: false,
      },
      loginObj: {
        emailAddress: '',
        password: '',
        userDetailId: 0,
      },
      fNameErr: '',
      lNameErr: '',
      cellErr: '',
      passErr: '',
      emailErr: '',
      emailExist: false,
      cellExist: false,
      emailValid: true,
      cellValid: true,
      openLoginEdit: false,
      openUserIsEdit: false,
      message: '',
      istart: false,
      assignStaff: [
        {name: 'Rich Martin'},
        {name: 'Erika Cowell'},
        {name: 'Michael Bloomberg'},
        {name: 'Chris Rock'},
        {name: 'Dave Goodman'}
      ],
      readMoreNote: false,
      datePickerVal: false,
      updateExpiryDate: '',
      isLoading: false,
      compDetail: null,
      planDetail: [],
      scY: 0,
      scTimer: 0,
      useDetailId: 0,
      isShowFullDesc: true,
      isShowShortDesc: true,
      mobileView: false,
      competitionDetailObj: [],
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    this.updateExpiryDate = new Date()
    window.scrollTo({top: 0})
    window.addEventListener('scroll', this.handleScroll);
    this.resizeWindowHandler();
    document.title = 'Staff Detail'
    this.getStaffDetail()
  },
  watch: {
    competitionDetailObj: {
      handler () {
        console.log('Watch On:-competitionDetailObj', this.competitionDetailObj)
      },
      deep: true
    }
  },
  methods: {
    updateUserLoginDetail (val) {
      console.log('this.staffInfoObj', this.staffInfoObj, val)
      if (val) {
        let isvalid = true
        if (this.loginObj.emailAddress === '') {
          this.emailErr = 'Email is required'
          isvalid = false
        }
        if (this.loginObj.password === '') {
          this.passErr = 'Password is required'
          isvalid = false
        }
        if (this.emailExist) {
          isvalid = false
        }
        if (!this.emailValid) {
          isvalid = false
        }
        if (isvalid) {
          this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
          this.loginObj.password = getEncryptedPassword(this.loginObj.password)
          ADMINAPI.UpdateStaffApi(
            'logindetail',  
            this.loginObj,
            response => {
              this.openLoginEdit = false
              this.getStaffDetail()
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            (error) => {
              this.openLoginEdit = false
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
          )
        }
      } else {
        this.openLoginEdit = false
      }
    },
    closeUserInfoEdit (val) {
      console.log('this.staffInfoObj', this.staffInfoObj)
      if (val) {
        let isvalid = true
        if (this.staffInfoObj.firstName === '') {
          this.fNameErr = 'First name is required'
          isvalid = false
        }
        if (this.staffInfoObj.lastName === '') {
          this.lNameErr = 'Last name is required'
          isvalid = false
        }
        if (this.staffInfoObj.phoneNo === '') {
          this.cellErr = 'Phone Number is required'
          isvalid = false
        }
        if (this.cellExist) {
          isvalid = false
        }
        if (!this.cellValid) {
          isvalid = false
        }
        if (isvalid) {
          ADMINAPI.UpdateStaffApi(
            'userinfo',  
            this.staffInfoObj,
            response => {
              this.openUserIsEdit = false
              this.getStaffDetail()
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            (error) => {
              this.openUserIsEdit = false
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
          )
        }
      } else {
        this.openUserIsEdit = false
      }
    },
    checkContactNumber () {
      let value = checkSpeialChar(this.staffInfoObj.phoneNo)
      console.log('value', value)
      if (value) {
        this.cellValid = false
        this.cellErr = 'Please enter valid contact number'
      } else if (this.staffInfoObj.phoneNo.length < 10) {
        this.cellValid = false
        this.cellErr = 'Please enter valid 10 digit contact number'
      } else {
        this.cellValid = true
        this.cellErr = ''
        this.checkExist('phone', this.staffInfoObj.phoneNo, this.staffInfoObj.userDetailId)
      }
    },
    emailHandler (emailId) {
      this.emailErr = this.emailValidation(emailId)
      if (this.emailErr === 'Email address is invalid') {
        this.emailValid = false
      } else {
        this.emailValid = true
        this.emailErr = ''
        this.checkExist('email', this.loginObj.emailAddress, this.loginObj.userDetailId)
      }
    },
    emailValidation (emailId) {
      if (emailId === '') {
        return ''
      } else if (validateEmail(emailId) === false) {
        return 'Email address is invalid'
      } else {
        return ''
      }
    },
    checkExist (from, value, contact) {
      ADMINAPI.CheckNumberExistStaff(
        from,
        value,
        contact,
        response => {
          if (from === 'phone' && response.Data) {
            this.cellErr = 'Phone number already exist'
            this.cellExist = true
          } else if (from === 'email' && response.Data) {
            this.emailExist = true
            this.emailErr = 'Email Address already exist' 
          } else {
            this.cellExist = false
            this.emailExist = false
          }
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      ) 
    },
    userEdit () {
      this.openUserIsEdit = true
    },
    logInDataEdit () {
      this.openLoginEdit = true
    },
    saveUpdate () {
      console.log('this.updateExpiryDate', this.updateExpiryDate)
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.UpdateExpiryDate(
        this.planDetail.subscriptionId,
        this.updateExpiryDate,
        '',
        response => {
          // this.getStaffDetail()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          this.isLoading = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getStaffDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetStaffDetail(
        parseInt(this.$route.params.userDetailId),
        response => {
          console.log(response.Data)
          let data = response.Data
          this.staffData = response.Data.staffInfo
          this.staffLoginData = response.Data.staffLoginDetail
          this.staffInfoObj.userDetailId = data.staffInfo.userDetailId
          this.staffInfoObj.firstName = data.staffInfo.firstName
          this.staffInfoObj.lastName = data.staffInfo.lastName
          this.staffInfoObj.phoneNo = data.staffInfo.phoneNo
          this.staffInfoObj.isActive = data.staffInfo.isActive
          this.loginObj.emailAddress = data.staffLoginDetail.emailAddress
          this.loginObj.password = data.staffLoginDetail.password
          this.loginObj.userDetailId = data.staffLoginDetail.userDetailId
          document.title = `Staff Detail: ${this.staffInfoObj.firstName + ' ' + this.staffInfoObj.lastName}`
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.isLoading = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    smoothScroll (target) {
      window.scrollTo({
        top: document.getElementById(target).offsetTop - 60,
        left: 0,
        behavior: "smooth",
      });
    },
    resizeWindowHandler() {
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    handleScroll () {
      if (this.scTimer) return
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY
        clearTimeout(this.scTimer)
        this.scTimer = 0
      }, 100)
    },
    toTop () {
      window.scrollTo({top: 0, behavior: "smooth"})
    },
    redirectToList() {
      // this.$router.push({name: 'Competition'})
      this.$router.go(-1)
    },
  }
}
</script>
<style scoped>

.lableWidth {
  width: 105px;
}
.dotwidth {
  width: 15px;
}
.list_top_card_btn {
  width: 83px !important;
  margin-right: 15px !important;
}
.ImageTag {
  max-height: 300px;
  width: auto;
}
</style>
